<template>
  <page-layout title="订单详情" :goback="true">
    <a-skeleton
      style="background-color: #fff; padding: 24px"
      v-if="detailSpinning"
      active
      :loading="detailSpinning"
      :paragraph="{ rows: 4 }"
    />
    <div id="order_detail" v-if="!detailSpinning">
      <div class="top">
        <div class="top_left">
          <div class="title">订单号：{{ orderNum }}</div>
          <div class="content">
            <span class="set_meal">
              {{ info.combo == 1 ? "单品订单" : "套餐订单" }}
            </span>
            <span
              v-if="info.isCust"
              class="set_meal marginL8"
              style="background-color: burlywood"
            >
              定制商品
            </span>
          </div>
        </div>
        <div class="top_right">
          <div class="icon_box">
            <img
              src="@/assets/img/order_icon0.svg"
              alt=""
              v-if="info.status == 0"
            />
            <img
              src="@/assets/img/order_icon1.svg"
              alt=""
              v-if="info.status == 1"
            />
            <img
              src="@/assets/img/order_icon2.svg"
              alt=""
              v-if="info.status == 2"
            />
            <img
              src="@/assets/img/order_icon3.svg"
              alt=""
              v-if="info.status == 3"
            />
            <img
              src="@/assets/img/order_icon4.svg"
              alt=""
              v-if="info.status == 4"
            />
            <img
              src="@/assets/img/order_icon5.svg"
              alt=""
              v-if="info.status == 5"
            />
            <img
              src="@/assets/img/order_icon1.svg"
              alt=""
              v-if="info.status == 6"
            />
            <img
              src="@/assets/img/order_icon0.svg"
              alt=""
              v-if="info.status == 7"
            />
          </div>
          <div>
            <!-- <div class="text" v-if="info.status == 0">等待付款</div> -->
            <div class="text">{{ info.orderStatus }}</div>
            <div
              class="num"
              v-if="
                info.status == 0 &&
                info.certificate_status != 1 &&
                info.certificate_status != 3
              "
            >
              {{ endTime }}
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="title">收货信息</div>
        <div class="receive_info">
          <div class="content">
            <div>收货人： {{ info.realName }}</div>
            <div>手机号码：{{ info.userPhone }}</div>
            <div>收货地址：{{ info.userAddress }}</div>
          </div>
          <a-button
            @click="editaddress"
            v-if="info.status == 0"
            class="font12 green"
          >
            修改地址
          </a-button>
          <a-button
            v-if="canConfirmReceipt"
            class="font12 green"
            @click="sureReceiving"
          >
            确认收货
          </a-button>
        </div>
      </div>
      <div
        class="box"
        style="padding-bottom: 24px"
        v-if="expressageList.length > 0"
      >
        <div class="box_head">
          <div class="title">配送信息</div>
        </div>
        <a-table
          :columns="expressageColumns"
          :data-source="expressageList"
          :pagination="false"
        >
          <template slot="action" slot-scope="text, record">
            <a-button
              class="seelogistics green font12"
              style="margin-right: 8px"
              @click="seeDetail(record)"
            >
              查看商品明细
            </a-button>
            <a-button
              class="seelogistics green font12"
              @click="seeExpress(record.freight_name, record.freight_billno)"
            >
              查询快递信息
            </a-button>
          </template>
        </a-table>
      </div>
      <productList :info="info" @subSuccess="subSuccess" />
      <div class="box">
        <div class="title">订单信息</div>
        <a-row class="order_info" :gutter="[0, 8]">
          <a-col :xxl="6" :xl="6" :lg="8">订单号：{{ info.orderId }}</a-col>
          <a-col :xxl="6" :xl="6" :lg="8">下单时间：{{ info.addTime }}</a-col>
          <a-col
            :xxl="6"
            :xl="6"
            :lg="8"
            v-if="info.status > 0 && info.status != 5"
            >支付时间：{{ info.payTime }}</a-col
          >
          <a-col :xxl="6" :xl="6" :lg="8" v-if="info.status == 6"
            >购买数量：{{ info.orderProductCount }}</a-col
          >
          <a-col
            :xxl="6"
            :xl="6"
            :lg="8"
            v-if="info.status != 5 && info.status != 0"
            >支付方式：{{ info.payName }}</a-col
          >
          <a-col :xxl="6" :xl="6" :lg="8" v-if="info.status == 5"
            >取消时间：{{ info.endOrderCancelTime }}</a-col
          >
          <!-- <a-col :xxl="6" :xl="6" :lg="8" v-if="info.pay_type == 2 && info.status > 0 && info.status != 5">支付方式：线下付款 -->
          <a-col
            :xxl="12"
            :xl="12"
            :lg="24"
            v-if="info.pay_type == 2 && info.status == 0"
          >
            支付方式：线下付款
            <span
              class="marginL8 red cur"
              @click="openImg"
              v-if="info.certificateStatus == 1"
            >
              查看付款凭证
            </span>
            <span class="marginL8 cur" v-if="info.certificateStatus == 2">
              付款凭证审核通过
            </span>
            <span class="marginL8 red cur" v-if="info.certificateStatus == 3">
              <span @click="$message.warning('请在\'去支付\'中重新支付')">
                付款凭证审核不通过
              </span>
            </span>
          </a-col>
          <a-col
            :xxl="6"
            :xl="6"
            :lg="8"
            v-if="info.status > 0 && info.status != 5"
            >商品总额：￥{{ info.totalPrice | price }}
          </a-col>
          <a-col
            :xxl="6"
            :xl="6"
            :lg="8"
            v-if="info.status > 0 && info.status != 5"
            >实际支付金额：￥{{ info.payPrice | price }}</a-col
          >
          <a-col
            :xxl="6"
            :xl="6"
            :lg="8"
            v-if="info.status > 0 && info.status != 5"
            >运费：￥{{ info.payPostage | price }}</a-col
          >
          <a-col :span="24">订单备注：{{ info.mark || "暂无备注" }}</a-col>
          <a-col :span="24">卖家备注：{{ info.adminMark || "暂无备注" }}</a-col>
        </a-row>
        <!-- <div class="upload" v-if="info.status == 0 && info.pay_type == 2">
          <span class="text">上传付款凭证：</span>
          <img v-if="imageUrl && info.certificateStatus == 1" :src="imageUrl" alt="avatar"
            style="width: 114px; height: 114px" />
          <a-upload class="avatar-uploader" name="image" listType="picture-card" :showUploadList="false"
            :customRequest="customRequest" v-if="
              info.certificate_type == 0 ||
              info.certificate_status == 3 ||
              info.certificate_type == 2
            ">
            <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="width: 114px; height: 114px" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
        </div> -->
        <div class="action_button">
          <div>
            <a-button @click="cancleOrder(info.orderId)" v-if="info.status == 0"
              >取消订单</a-button
            >
            <a-button
              type="primary"
              class="marginL8"
              @click="pay"
              v-if="info.status == 0"
              >去支付</a-button
            >
            <!-- <a-button type="primary" class="marginL8" @click="submit" v-if="
              (
                info.certificate_type == 2 &&
                info.certificate_status == 0) ||
              (info.certificate_status == 3 && info.certificate_type == 1)
            " :disabled="!isNewUp">提交</a-button> -->
            <a-button
              type="primary"
              disabled
              class="marginL8"
              @click="pay"
              v-if="info.certificate_status == 1"
              >审核中
            </a-button>

            <a-button @click="againBuy" type="primary" v-if="info.status == 4"
              >再来一单</a-button
            >
          </div>
        </div>
      </div>
      <a-modal
        title="查询快递信息"
        :visible="visible5"
        :confirm-loading="confirmLoading"
        :footer="null"
        :width="870"
        @cancel="handleCancel5"
      >
        <a-alert
          v-if="!currentState && (expressInfo.Traces || []).length == 0"
          message="暂不支持快递轨迹查询，可复制单号自行查询"
          type="warning"
          show-icon
          style="margin-bottom: 20px"
        />
        <div>
          <span>物流名称：{{ expressInfo.deliveryCode }}</span>
          <span class="wuliu">物流单号:{{ expressInfo.deliveryId }}</span>
        </div>
        <a-steps :current="currentState" status="finish" class="speed_line">
          <a-step title="暂无轨迹信息" />
          <a-step title="已揽收" />
          <a-step title="在途中" />
          <a-step title="签收" />
          <a-step title="问题件" />
        </a-steps>
        <div class="steps-wrap">
          <ul>
            <li v-for="(item, index) in expressInfo.Traces" :key="index">
              <span class="time">{{ item.AcceptTime }}</span>
              <div class="circle-icon"></div>
              <span v-html="item.AcceptStation" class="message"></span>
            </li>
          </ul>
        </div>
      </a-modal>
      <a-modal
        title="修改地址"
        :visible="visible1"
        :confirm-loading="confirmLoading"
        @ok="onSubmit"
        @cancel="handleCancel1"
        :width="500"
      >
        <a-form-model
          ref="ruleForm"
          :model="form1"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="收件人" prop="addressee">
            <a-input v-model="form1.addressee" />
          </a-form-model-item>
          <a-form-model-item label="联系方式" prop="addressee_phone">
            <a-input v-model="form1.addressee_phone" />
          </a-form-model-item>
          <a-form-model-item label="所在地区" class="ssq">
            <a-cascader
              :field-names="{ label: 'n', value: 'v', children: 'c' }"
              :options="options"
              :default-value="value"
              placeholder="选择省市区"
              @change="editAddressChange"
            >
              <template
                slot="displayRender"
                slot-scope="{ labels, selectedOptions }"
              >
                <span
                  v-for="(label, index) in labels"
                  :key="selectedOptions[index].value"
                >
                  <span> {{ label }} / </span>
                </span>
              </template>
            </a-cascader>
          </a-form-model-item>
          <a-form-model-item label="详细地址" prop="detail">
            <a-input v-model="form1.detail" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <look-img :img="info.certificate_image" ref="imgBox"></look-img>
    </div>
    <child-product
      ref="childProduct"
      :title="otherTitle"
      :columns="otherColumns"
      :data="otherProduct"
    ></child-product>
  </page-layout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";
import { price } from "@/utils/filters";
import childProduct from "@/components/childProduct";
import productList from "./components/productList.vue";
import {
  editAddress,
  // uploadCertificate,
  takeDistribution,
  orderExpress,
  // deliverProduct,
  // nonedDeliverProduct,
  batchTakeDistribution,
  // finishDistribution,
  orderCancel,
  orderDetail2,
  refundDetail,
  orderDetail,
  getDeliverInfo,
  sureTake,
  buyAgain,
} from "@/api/myOrder";
import { cityList } from "@/api/receivingAdress";
import lookImg from "@/components/lookImg/lookImg";
var orderTime = null;
const expressageColumns = [
  {
    title: "配送方式",
    dataIndex: "way",
    key: "way",
    width: 200,
    customRender: () => {
      return "快递配送";
    },
    align: "center",
  },
  {
    title: "快递公司",
    dataIndex: "freight_name",
    key: "freight_name",
    align: "center",
  },
  {
    title: "快递单号",
    dataIndex: "freight_billno",
    key: "freight_billno",
    align: "center",
  },
  {
    title: "发货时间",
    dataIndex: "freight_time",
    key: "freight_time",
    align: "center",
  },
  {
    title: "确认收货时间",
    dataIndex: "take_time",
    key: "take_time",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

const columns2 = [
  {
    title: "书籍名称",
    dataIndex: "goods",
    key: "goods",
    width: 362,
    scopedSlots: { customRender: "goods" },
    align: "center",
  },
  {
    title: "分类",
    dataIndex: "cateName",
    key: "cateName",
    align: "center",
  },
  {
    title: "出版社",
    dataIndex: "publishing_house",
    key: "publishing_house",
    align: "center",
  },
  {
    title: "作者",
    dataIndex: "author",
    key: "author",
    align: "center",
  },
  {
    title: "数量",
    dataIndex: "cartNum",
    key: "cartNum",
    align: "center",
  },
  {
    title: "零售价",
    dataIndex: "price",
    key: "price",
    align: "center",
  },
];
const otherColumns = [
  {
    title: "序号",
    dataIndex: "index",
    key: "index",
    width: 80,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: "商品名称",
    dataIndex: "store_name",
    key: "store_name",
    width: 280,
    align: "left",
  },
  {
    title: "商品规格",
    dataIndex: "spec_name",
    key: "spec_name",
    width: 280,
    align: "center",
  },
  {
    title: "数量",
    dataIndex: "number",
    key: "number",
    width: 130,
    align: "center",
  },
  {
    title: "单位",
    dataIndex: "unit",
    key: "unit",
    width: 150,
    align: "center",
  },
];
const panes = [
  { title: "已发货商品", key: "1" },
  { title: "未发货商品", key: "2" },
];
export default {
  name: "orderDetail",
  components: { PageLayout, lookImg, childProduct, productList },
  filters: {
    price,
  },
  data() {
    return {
      nthis: this,
      columns2,
      visible1: false,
      visible2: false,
      visible3: false,
      visible5: false,
      confirmLoading: false,
      orderNum: "23637631147441414",
      data: [],
      data1: [],
      size: "small",
      type: 2,
      loading: false,
      orderState: 3,
      activeKey: "1",
      panes,
      province: [],
      city: [],
      district: [],
      info: {},
      form: {
        orderId: "",
        invoiceId: "",
        type: "1",
        recipients: "",
        recipientsPhone: "",
        address: "",
      },
      address: "",
      form1: {
        addressee: "",
        addressee_phone: "",
        province: "",
        city: "",
        district: "",
        detail: "",
      },
      form2: {
        headerType: "2",
        name: "",
        dutyNumber: undefined,
        bank: undefined,
        cardNumber: undefined,
        username: "",
        drawerPhone: "",
        email: "",
        isDefault: true,
      },
      options: [],
      expressInfo: {},
      currentState: 0,
      rules: {
        addressee: [{ required: true, message: "请填写姓名", trigger: "blur" }],
        addressee_phone: [
          { required: true, message: "请填写电话", trigger: "blur" },
        ],
        detail: [
          { required: true, message: "请填写详细地址", trigger: "blur" },
        ],
      },
      rules1: {
        name: [{ required: true, message: "请填写公司名称", trigger: "blur" }],
        dutyNumber: [
          { required: true, message: "请填写企业税号", trigger: "blur" },
        ],
        bank: [{ required: true, message: "请填写开户银行", trigger: "blur" }],
        cardNumber: [
          { required: true, message: "请填写开户行账号", trigger: "blur" },
        ],
        username: [{ required: true, message: "请填写姓名", trigger: "blur" }],
        drawerPhone: [
          { required: true, message: "请填写电话", trigger: "blur" },
        ],
        email: [{ required: true, message: "请填写邮箱", trigger: "blur" }],
      },
      sonOrderId: "",
      sendType: "",
      childOrderIds: "",
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      endTime: "",
      value: undefined,
      isNewUp: false,
      data2: "",
      refundDetails: {},
      visible4: false,
      refundItem: false,
      expressageList: [],
      expressageColumns,
      uploadLoading: false,
      otherProduct: [],
      otherTitle: "",
      detailSpinning: false,
      otherColumns,
    };
  },
  computed: {
    isWechatPay() {
      return this.info.pay_type == 6 || this.info.pay_type == 7;
    },
    // 是否可以确认收货
    canConfirmReceipt() {
      if (this.info.status == 3) {
        return true;
      } else if (this.info.status == 2) {
        this.info.productInfo.forEach((item) => {
          if (
            item.refund_num > 0 &&
            item.refund_num != item.cartNum - item.task_num
          ) {
            return false;
          } else if (
            item.refund_num > 0 &&
            item.whetherToParticipateRefund != 2
          ) {
            return false;
          } else if (item.refund_num == 0 && item.cartNum > item.task_num) {
            return false;
          }
        });
        return true;
      }
      return false;
    },
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
          },
        }),
      };
    },
  },
  created() {
    this.getData();
    cityList().then((res) => {
      this.options = res.data.data;
    });
  },
  mounted() {},
  methods: {
    seeDetail(record) {
      this.otherProduct = record.product_data;
      this.otherTitle = "商品明细";
      this.$refs.childProduct.visible = true;
    },
    subSuccess() {
      this.getData();
    },
    againBuy() {
      console.log(this.info.orderId);
      const hide = this.$message.loading("订单生成中,请稍等...", 0);
      buyAgain({ orderSn: this.info.orderId })
        .then((res) => {
          hide();
          if (res.data.data.cart_id) {
            this.$router.push({
              path: "/bookBuy/submitOrder",
              query: {
                cartId: res.data.data.cart_id,
                new: 1,
              },
            });
          } else {
            this.$message.error("该订单包含商品已下架或库存不足");
          }
        })
        .catch(() => {
          hide();
        });
    },

    openOther(record) {
      this.otherProduct = record.child;
      this.otherTitle = record.storeName + "套餐详情";
      this.$refs.childProduct.visible = true;
    },
    //确认收货
    sureReceiving() {
      if (this.isWechatPay) {
        this.$info({
          title: "微信支付订单请前往 约读优选服务平台 小程序进行收货",
        });
      } else {
        sureTake(this.info.orderId).then(() => {
          this.getData();
          this.$message.success("收货成功");
        });
      }
    },
    getDeliverInfos() {
      getDeliverInfo(this.info.orderId).then((res) => {
        this.expressageList = res.data.data;
      });
    },

    handleCancel4() {
      this.visible4 = false;
    },
    lookrefundDetail(id) {
      console.log(id);
      refundDetail(id)
        .then((res) => {
          this.refundDetails = res.data.data;
        })
        .finally(() => {
          this.visible4 = true;
        });
    },
    openImg() {
      this.$refs.imgBox.visible = true;
    },
    cancleOrder(id) {
      orderCancel(id).then(() => {
        this.$message.success("取消成功！");
        this.$router.push({
          path: "myOrder",
        });
      });
    },
    changeDate(time) {
      time = +new Date(time);
      orderTime = setInterval(() => {
        let newTime = +new Date();
        if (time - newTime > 0) {
          let sTime = parseInt((time - newTime) / 1000);
          let hours = this.addZero(Math.floor(sTime / 60 / 60));
          let min = this.addZero(Math.floor(sTime / 60));
          let sed = this.addZero(Math.floor(sTime % 60));
          this.endTime = hours + ":" + min + ":" + sed + "后自动取消";
        } else {
          clearInterval(orderTime);
          this.endTime = "00:00:00后自动取消";
        }
      }, 1000);
    },
    addZero(val) {
      return val < 10 ? "0" + val : val;
    },

    onSearch(e) {
      console.log(e);
      orderDetail2(this.orderNum, e).then((res) => {
        this.info = res.data.data;
      });
    },
    getData() {
      this.detailSpinning = true;
      this.orderNum = this.$route.query.id;
      orderDetail(this.orderNum)
        .then((res) => {
          this.info = res.data.data;
          try {
            this.info.custValue = JSON.parse(this.info.custValue);
          } catch (e) {
            this.info.custValue = [];
          }
          this.info.productInfo.forEach((i) => {
            i.isCust = this.info.isCust;
            i.custValue = this.info.custValue;
          });
          if (this.info.status == 0) {
            this.changeDate(this.info.endOrderCancelTime);
          }
          this.getDeliverInfos();
          let list = JSON.parse(JSON.stringify(this.info.productInfo));
          this.refundProductList = list.map((item) => {
            item.maxNum = item.cartNum - item.refund_num;
            item.oCartNum = item.cartNum;
            item.cartNum = item.cartNum - item.refund_num;
            return item;
          });
        })
        .finally(() => {
          this.detailSpinning = false;
        });
    },
    editaddress() {
      this.visible1 = true;
      this.form1.addressee = this.info.realName;
      this.form1.addressee_phone = this.info.userPhone;
      let list = this.info.userAddress.split(" ");
      this.form1.province = list[0];
      this.form1.city = list[1];
      this.form1.district = list[2];
      this.form1.detail = list[3];
    },
    type1(id) {
      this.form.invoiceId = id;
    },
    editAddressChange(value, selectedOptio) {
      console.log(value);
      this.value = value;
      this.form1.province = selectedOptio[0].n;
      this.form1.city = selectedOptio[1].n;
      this.form1.district = selectedOptio[2].n;
    },
    onSubmit() {
      if (this.form1.district == undefined || this.form1.district == "") {
        this.$message.warning("省市区不能为空");
        return;
      }
      console.log(this.form1.addressee_phone);
      if (!/^[1][3-9][\d]{9}/.test(this.form1.addressee_phone)) {
        this.$message.warning("请输入正确的手机号");
        return false;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          editAddress(this.info.orderId, this.form1)
            .then(() => {
              this.$message.success("修改成功！");
              this.getData();
              this.visible1 = false;
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        } else {
          console.log("请填写全信息");
          return false;
        }
      });
    },

    // submit() {
    //   uploadCertificate(this.info.orderId, {
    //     image: this.imageUrl,
    //   }).then((res) => {
    //     if (res.data.status == 200) {
    //       this.$message.success("提交成功!");
    //       this.getData();
    //       this.getInvoice();
    //     } else {
    //       this.$message.error(res.data.msg);
    //     }
    //   });
    // },
    callback(key) {
      if (key == 1) {
        this.getSendOrder(this.info.orderId);
      }
      if (key == 2) {
        this.getNoSendOrder(this.info.orderId);
      }
    },
    callback1(key) {
      console.log(key);
    },
    callback2(key) {
      if (key == 1) {
        this.getFinishOrder(this.info.orderId);
      }
      if (key == 2) {
        this.getNoSendOrder(this.info.orderId);
      }
    },
    pay() {
      this.$router.push({
        path: "/bookBuy/orderPay",
        query: { orderNum: this.info.orderId },
      });
    },
    allReceiving(data, data1) {
      this.visible3 = true;
      if (data1) {
        this.sonOrderId = data1;
      }
      this.sendType = data;
    },
    onChange(value, selectedOptio) {
      console.log(value);
      this.form.address =
        selectedOptio[0].n +
        "-" +
        selectedOptio[1].n +
        "-" +
        selectedOptio[2].n;
    },
    handleOk3() {
      if (this.sendType == "single") {
        takeDistribution(this.sonOrderId)
          .then(() => {
            this.$message.success("收货成功！");
            this.getData();
          })
          .finally(() => {
            this.visible3 = false;
          });
      }
      if (this.sendType == "all") {
        batchTakeDistribution({
          childOrderIds: this.childOrderIds,
        })
          .then(() => {
            this.$message.success("收货成功！");
            this.getData();
          })
          .finally(() => {
            this.visible3 = false;
          });
      }
    },
    addBilling() {
      this.visible2 = true;
    },
    seeExpress(data, data1) {
      console.log(data, data1);
      this.visible5 = true;
      let params = {
        deliveryCode: data,
        deliveryId: data1,
      };
      this.expressInfo = params;

      orderExpress(params).then((res) => {
        this.currentState = Number(res.data.data.State);
        this.expressInfo.Traces = res.data.data.Traces;
      });
    },
    handleCancel1() {
      this.visible1 = false;
    },
    handleCancel5() {
      this.visible5 = false;
    },
  },
};
</script>

<style lang="less">
.ant-tabs-nav .ant-tabs-tab-active {
  color: @primary-color;
}

.ant-tabs-ink-bar {
  background-color: @primary-color;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-table-thead {
  tr {
    th {
      font-size: 13px;
    }
  }
}

.ant-table-tbody {
  tr {
    td {
      font-size: 12px;
    }
  }
}

.ssq label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.mr26 {
  margin-right: 26px;
}

.font20 {
  font-size: 20px;
}

.img_hint {
  font-size: 12px;
  color: #999;
}
</style>
<style lang="less" scoped>
@import "orderDetail";
</style>
