<template>
    <div>
        <a-modal :title="title" :visible="visible" :footer="null" :width="1200" @cancel="handleCancel">
            <a-table :columns="columns" :data-source="data">
                <template slot="index" slot-scope="text,record,index">{{ index+ 1 }}</template>
                <div slot="goods" slot-scope="text, record" class="goods goods_g">
                    <img :src="record.image" alt="" class="img" v-if="record.image" />
                    <div>
                        <div>
                            <div class="describe">{{ record.storeName }}</div>
                            <div style="margin-top: 6px" v-show="record.specName">
                                商品规格：{{ record.specName }}
                            </div>
                        </div>
                    </div>
                </div>
            </a-table>

        </a-modal>
    </div>
</template>

<script>
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        key: "index",
        width: 80,
        scopedSlots: { customRender: "index" },
        align: "center",
    },
    {
        title: "商品名称",
        dataIndex: "goods",
        key: "goods",
        width: 280,
        scopedSlots: { customRender: "goods" },
        align: "left",
    },
    {
        title: "ISBN",
        dataIndex: "isbn",
        key: "isbn",
        align: "center",
    },
    {
        title: "商品编码",
        dataIndex: "barCode",
        key: "barCode",
        align: "center",
    },
    {
        title: "数量",
        dataIndex: "cart_num",
        customRender: (text, record) => {
            return record.cart_num || record.cartNum
        },
        key: "cart_num",
        align: "center",
    },
    {
        title: "单位",
        dataIndex: "unit",
        key: "unit",
        align: "center",
    },
    {
        title: "市场价",
        dataIndex: "otPrice",
        key: "otPrice",
        customRender: (text) => {
            return '￥'+(typeof text == 'number' ? text.toFixed(2) : text)
        },
        align: "center"
    },
    {
        title: "订货价",
        dataIndex: "deductionUnitPrice",
        customRender: (text) => {
            return '￥'+(typeof text == 'number' ? text.toFixed(2) : text)
        },
        align: "center",
    },
    {
        title: "金额",
        dataIndex: "totalPrice",
        key:"totalPrice",
        customRender: (text) => {
            return '￥'+(typeof text == 'number' ? text.toFixed(2) : text)
        },
        align: "center",
    },
];
export default {
    props: {
        title: {
            default: "标题",
            type: String
        },
        data: {
            default: () => [],
            type: Array
        },
        columns:{
            default:()=>columns,
            type: Array
        }
    },
    name: '',
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        handleCancel() {
            this.visible = false
        }
    },
}

</script>
<style scoped lang="less">
.goods {
    text-align: center;
    font-size: 12px;

    .describe {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
}

.goods_g {
    display: flex;
    text-align: left;
    align-items: center;

    img {
        margin-right: 8px;
        width: 48px;
        height: 48px;
    }

    .describe {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
</style>