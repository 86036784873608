<template>
    <div>
        <a-modal :visible="visible" :width="750" title="付款凭证图片" @cancel="cancel" @ok="down" okText="下载">
            <div class="img_box">
                <img :src="img" alt="" class="img">
            </div>
        </a-modal>
    </div>
</template>

<script>
export default {
    props:['img'],
    data(){
        return{
            visible:false
        }
    },
    methods:{
        cancel(){
            this.visible=false
        },
        down(){
            window.location.href=this.img
        }
    }
}
</script>

<style scoped>
.img{
    width: 600px;
    height: 600px;
}
.img_box{
    text-align: center;
}
</style>