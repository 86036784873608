<template>
  <!-- 订单详情商品明细 -->
  <div class="box" style="padding-bottom: 24px">
    <div class="box_head">
      <div class="title">商品明细</div>
      <div class="flex" v-if="showRefundViews">
        <a-button type="primary" @click="openRefund"> 申请售后 </a-button>
        <a-tooltip>
          <template slot="title"> 请在售后订单查看进度 </template>
          <p class="title2" v-if="refundStatusDesc">
            <a-icon type="info-circle" /> {{ refundStatusDesc }}
          </p>
        </a-tooltip>
        <a class="title1" @click="getRule(), (afterModal = true)">
          售后规则
          <a-icon type="question-circle" />
        </a>
      </div>
    </div>
    <a-table
      bordered
      row-key="id"
      :columns="columns"
      :data-source="info.productInfo"
      :pagination="false"
      :scroll="{ x: 1200 }"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        hideDefaultSelections: true,
        selectedRows: selectedRows,
        getCheckboxProps: getCheckboxProps,
        onSelectAll: onSelectAllRow,
        onChange: onSelectChange,
        onSelect: onSelectOneRow,
      }"
    >
      <div slot="index" slot-scope="text, record, index">
        {{ index + 1 }}
      </div>
      <div slot="goods" slot-scope="text, record">
        <div class="goods goods_g">
          <img :src="record.image" alt="" class="img" />
          <div>
            <div class="describe">{{ record.storeName }}</div>
            <div style="margin-top: 6px" v-show="record.specName">
              <div>商品规格：{{ record.specName }}</div>
              <div>
                <p v-if="record.task_num == 0">未发货</p>
                <p
                  v-else-if="
                    record.task_num > 0 && record.task_num == record.cartNum
                  "
                >
                  已全部发货
                </p>
                <p v-else>
                  已发货:{{ record.task_num }}件, 未发货:{{
                    record.cartNum - record.task_num
                  }}件
                </p>
              </div>
              <p v-if="record.refundOnlyNum > 0">
                已申请仅退款:{{ record.refundOnlyNum }}件
              </p>
              <p v-if="record.refund_num - record.refundOnlyNum > 0">
                已申请退货退款:{{ record.refund_num - record.refundOnlyNum }}件
              </p>
              <p
                v-if="
                  info.status == 3 && record.whetherToParticipateRefund != 1
                "
              >
                {{
                  record.whetherToParticipateRefund == 1
                    ? ""
                    : record.whetherToParticipateRefund == 2
                    ? "售后退款成功"
                    : "售后退款中"
                }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="record.child.length > 1"
          class="more_product"
          @click="openOther(record)"
        >
          还有{{ record.child.length }}件商品，点击查看
        </div>
      </div>
      <div slot="cust" slot-scope="text">
        <a v-if="text == 1" href="javascript:;" @click="custVisible = true">
          查看
        </a>
        <span v-else>/</span>
      </div>
      <div slot="active" slot-scope="text, item">
        <template v-if="showRefundViews">
          <a-button
            v-if="item._canReturnGoodsAndReturnMoneyNum > 0"
            @click="openRefundPart(item, 2)"
          >
            退货退款
            <span class="color_E04B4B">
              {{ item._canReturnGoodsAndReturnMoneyNum }}{{ item.unit }}
            </span>
          </a-button>
          <a-button
            v-if="item._canOnlyReturnMoneyNum > 0"
            :style="{
              marginTop: item._canReturnGoodsAndReturnMoneyNum ? '8px' : '',
            }"
            @click="openRefundPart(item, 1)"
          >
            仅退款
            <span class="color_E04B4B">
              {{ item._canOnlyReturnMoneyNum }}{{ item.unit }}
            </span>
          </a-button>
          <span
            v-if="
              item._canReturnGoodsAndReturnMoneyNum == 0 &&
              item._canOnlyReturnMoneyNum == 0
            "
          >
            /
          </span>
        </template>
        <span v-else>/</span>
      </div>
    </a-table>

    <div class="gray font14 text_r mt32">
      订单金额：<span class="origin mr26">
        ¥{{ info.totalPrice | price }}元
      </span>
      运费：<span class="origin mr26">¥{{ info.payPostage | price }}元</span>
      应付金额：<span class="origin font20">
        ¥{{ info.payPrice | price }}元
      </span>
    </div>

    <a-modal
      :width="529"
      title="申请售后"
      :visible="refundItem"
      :confirm-loading="shloading"
      @cancel="handleRefund"
      @ok="submitRefund"
    >
      <a-form-model
        :model="refundForm"
        :label-col="labelCol"
        :wrapper-col="{ span: 18 }"
        class="mb0"
      >
        <!-- v-if="type != 1" -->
        <a-form-model-item label="售后类型">
          <a-radio-group v-model="type" disabled>
            <a-radio :value="1">仅退款</a-radio>
            <a-radio :value="2">退货退款</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="售后原因">
          <a-select
            v-model="refundForm.applyCase"
            style="width: 100%"
            placeholder="请选择售后原因"
          >
            <a-select-option
              :value="item"
              v-for="(item, index) in reasonList"
              :key="index"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="售后商品">
          <div style="overflow-y: scroll; max-height: 350px">
            <div
              class="refund_product"
              v-for="(item, index) in refundProductList"
              :key="index"
            >
              <img :src="item.image" alt="" />
              <div class="product_info">
                <span>{{ item.storeName }}</span>
                <span>规格：{{ item.specName }}</span>
                <span>单价：¥{{ item.deductionUnitPrice | price }}</span>
              </div>
              <div class="product_num">
                <span v-if="type == 1" style="color: gray">
                  x{{ item.num }}
                </span>
                <a-input-number
                  v-else
                  style="width: 65px"
                  size="small"
                  :value="item.num"
                  :min="0"
                  :max="item.maxValue"
                  @change="(event) => changeNum(event, item, index)"
                />
              </div>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item
          label="运费退款"
          v-if="info.status == 6 && isShowRefund"
        >
          <span class="price_color">￥{{ info.payPostage | price }}</span>
        </a-form-model-item>
        <a-form-model-item label="退款合计">
          <span class="price_color">￥{{ refundTotalPrice | price }}</span>
          <span v-if="totalNumber == refundTotalNum">
            （含运费￥{{ info.payPostage }}）
          </span>
        </a-form-model-item>
        <a-form-model-item label="上传凭证" v-if="type == 2">
          <div class="img_hint">
            <a-icon type="exclamation-circle" />
            <!-- 照片需要包含随货清单、书籍质量问题照片、快递外包装照片 -->
            请上传实物凭证，有助于商家更好处理售后问题片
          </div>
          <div class="upload_list">
            <div v-if="imageUrl.length > 0" class="upload_img">
              <div v-for="(item, index) in imageUrl" :key="index" class="pr">
                <img
                  :src="item"
                  alt="avatar"
                  style="width: 68px; height: 68px"
                />
                <a-icon type="close" class="close" @click="delImg(index)" />
              </div>
            </div>
            <a-upload
              class="avatar-uploader"
              name="image"
              listType="picture-card"
              :showUploadList="false"
              :customRequest="customRequest"
              :multiple="true"
            >
              <div v-if="imageUrl.length < 3">
                <a-icon type="plus" style="font-size: 16px" />
              </div>
            </a-upload>
            <div class="upload_warning">
              <p>图片大小5M以内</p>
              <p>最多可传3张</p>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item label="售后说明">
          <a-textarea
            v-model="refundForm.applyExplain"
            placeholder="请输入售后说明"
            :rows="4"
          />
          <p class="annotation">
            注：申请售后后不可取消，售后申请通过后，支付金额将原路退回。
          </p>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      title="售后规则"
      :visible="afterModal"
      :footer="null"
      :width="480"
      @cancel="afterModal = false"
    >
      <div v-html="afterRule"></div>
    </a-modal>
    <child-product
      ref="childProduct"
      :title="otherTitle"
      :data="otherProduct"
    />

    <a-modal
      title="定制内容"
      :visible="custVisible"
      :footer="null"
      :width="650"
      @cancel="custVisible = false"
    >
      <a-descriptions bordered :column="1" size="small">
        <a-descriptions-item
          v-for="(item, index) in info.custValue"
          :key="index"
          :label="item.name"
        >
          <span v-if="item.type == 1">{{ item.content }}</span>
          <template v-if="item.type == 2 && item.content">
            <img
              :src="val"
              alt="#"
              style="
                width: 150px;
                margin: 5px;
                aspect-ratio: 1;
                object-fit: cover;
              "
              v-for="(val, index2) in item.content.split(',')"
              :key="index2"
            />
          </template>
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
  </div>
</template>

<script>
import {
  refundReason,
  uploadImg,
  afterRules,
  applyRefund,
} from "@/api/myOrder";
import { price } from "@/utils/filters";
import { math } from "@/utils/math";
import childProduct from "@/components/childProduct";

export default {
  name: "ProductList",
  components: { childProduct },
  props: {
    // 订单详情
    // status: 0待付款  1已付款/待审核  2部分已发货  3待收货  4已完成  5已取消 6待发货 7已退款
    // refundStatus  0未申请售后 1申请中 2退款中 3已完成 4拒绝
    info: {},
  },
  filters: {
    price,
  },
  data() {
    return {
      refundItem: false,
      shloading: false,
      refundForm: {},
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      reasonList: [], //售后原因列表
      imageUrl: [],
      afterRule: "",
      afterModal: false,
      selectedRowKeys: [], // 售后选中的商品ID
      selectedRows: [], // 售后选中的商品
      refundProductList: [], //售后选中的商品
      type: 0, // 1仅退款  2退货退款
      columns,
      otherProduct: [],
      otherTitle: "",
      custVisible: false,
    };
  },
  watch: {
    info: {
      handler(val) {
        val.productInfo.forEach((item) => {
          // 仅退款
          item._canOnlyReturnMoneyNum =
            item.cartNum - item.task_num - item.refundOnlyNum;
          // 退货退款
          item._canReturnGoodsAndReturnMoneyNum =
            item.task_num - item.refundPrice - item.refundSales;
          // -1不可以售后 0都可以 1仅退款 2退货退款
          item._canRefundType = -1;
          if (
            item._canOnlyReturnMoneyNum > 0 &&
            item._canReturnGoodsAndReturnMoneyNum == 0
          ) {
            item._canRefundType = 1;
          } else if (
            item._canOnlyReturnMoneyNum == 0 &&
            item._canReturnGoodsAndReturnMoneyNum > 0
          ) {
            item._canRefundType = 2;
          } else if (
            item._canOnlyReturnMoneyNum > 0 &&
            item._canReturnGoodsAndReturnMoneyNum > 0
          ) {
            item._canRefundType = 0;
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    showRefundViews() {
      return (
        (this.info.status == 2 ||
          this.info.status == 3 ||
          this.info.status == 4 ||
          this.info.status == 6) &&
        !this.info.isCust &&
        this.info.isAfter
      );
    },
    refundStatusDesc() {
      return {
        0: "",
        1: "售后申请中",
        2: "售后退款中",
        3: "售后已完成",
        4: "售后被拒绝",
      }[this.info.refundStatus];
    },
    isShowRefund() {
      return this.refundProductList.every((item) => {
        return item.cartNum + item.refund_num == item.oCartNum;
      });
    },
    // 订单商品总数量
    totalNumber() {
      return math.subtract(
        this.info.orderProductCount,
        this.info.returnTotalNum
      );
    },
    // 售后总金额
    refundTotalPrice() {
      let totalPrice = 0;
      let totalNumber = 0;
      this.refundProductList.forEach((item) => {
        totalPrice = math.add(
          math.multiply(item.deductionUnitPrice * item.num),
          totalPrice
        );
        totalNumber = math.add(item.num, totalNumber);
      });
      if (this.totalNumber == totalNumber) {
        totalPrice = math.add(this.info.payPostage, totalPrice);
      }
      return totalPrice;
    },
    // 售后总数量
    refundTotalNum() {
      return this.refundProductList.reduce((a, b) => {
        return a + b.num;
      }, 0);
    },
  },
  methods: {
    // 获取售后规则
    getRule() {
      afterRules().then((res) => {
        this.afterRule = res.data.data[0];
      });
    },
    // 售后是否可选
    getCheckboxProps(record) {
      console.log("是否可选", record);
      return {
        props: {
          disabled:
            record.cartNum === record.refund_num || !this.showRefundViews,
        },
      };
    },
    onSelectOneRow(record, check) {
      if (!check) {
        return false;
      }
      if (record._canRefundType == -1) {
        this.deleteSelectedRecord(record);
        return this.$message.warning("本商品不可进行售后");
      }
      if (this.selectedRows.length == 1) {
        if (record._canRefundType == 0) {
          this.deleteSelectedRecord(record);
          return this.$message.warning("请选择只有单一售后类型的商品");
        } else {
          return false;
        }
      }
      const firstRow =
        this.selectedRows.find((item) => item.id != record.id) || {};
      if (record._canRefundType != firstRow._canRefundType) {
        this.$message.warning("请选择具有相同售后类型的商品");
        this.deleteSelectedRecord(record);
        return false;
      }
    },
    onSelectAllRow() {
      if (this.info.status == 2) {
        this.$message.warning("不可进行全选操作");
        this.selectedRowKeys = [];
        this.selectedRows = [];
      }
    },
    deleteSelectedRecord(record) {
      var index1 = this.selectedRowKeys.indexOf(record.id);
      if (index1 != -1) {
        this.selectedRowKeys.splice(index1, 1);
      }
      var index2 = this.selectedRows.findIndex((i) => record.id === i.id);
      if (index2 != -1) {
        this.selectedRows.splice(index2, 1);
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      // type: 1仅退款  2退货退款
      // this.type = this.info.status == 1 || this.info.status == 6 ? 1 : 2;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 全部发货的申请售后
    openRefund() {
      if (this.selectedRowKeys.length == 0) {
        return this.$message.warning("请选择售后商品");
      }
      // type: 1仅退款  2退货退款
      // this.type = this.info.status == 1 || this.info.status == 6 ? 1 : 2;
      this.type = this.selectedRows[0]._canRefundType == 1 ? 1 : 2;
      this.setProductList(this.selectedRows);
      this.getRefundReason();
      this.refundItem = true;
    },
    // 部分发货的申请售后
    openRefundPart(item, e) {
      this.type = e;
      this.setProductList([item]);
      this.getRefundReason();
      this.refundItem = true;
    },
    setProductList(list) {
      this.refundProductList = list.map((item) => {
        //可进行售后的数量   task_num//发货数量  有productId
        let number =
          this.type == 2
            ? math.subtract(item.task_num - item.refundPrice)
            : math.subtract(item.cartNum - item.task_num - item.refundOnlyNum);
        return {
          ...item,
          maxValue: number,
          num: number,
        };
      });
    },
    changeNum(e, item, index) {
      if (!e) {
        this.$set(this.refundProductList[index], "num", 0);
      } else if (e <= item.maxValue) {
        this.$set(this.refundProductList[index], "num", e);
      } else {
        this.$message.warning("已超出可退最大数量");
      }
    },
    getRefundReason() {
      //售后原因
      refundReason().then((res) => {
        this.reasonList = res.data.data;
      });
    },
    handleRefund() {
      this.refundItem = false;
    },
    delImg(index) {
      this.imageUrl.splice(index, 1);
    },
    customRequest({ file }) {
      if (file.type != "image/jpeg" && file.type != "image/png") {
        this.$message.error("请上传JPG或PNG格式文件");
        return false;
      }
      new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
          this.baseImageUrl = fileReader.result;
          this.upLoadImgs();
        };
      });
    },
    upLoadImgs() {
      uploadImg({ file: this.baseImageUrl }).then((res) => {
        if (res.data.status == 200) {
          if (this.imageUrl.length < 3) {
            this.imageUrl.push(res.data.data.url);
          } else {
            this.$message.warning("最多上传3张凭证");
          }
          // this.isNewUp = true;
        } else {
          this.$Message.error(res.data.msg);
        }
      });
    },
    openOther(record) {
      this.otherProduct = record.child;
      this.otherTitle = "订单号：" + this.info.orderId;
      this.$refs.childProduct.visible = true;
    },
    submitRefund() {
      if (!this.refundForm.applyCase) {
        this.$message.warning("请选择售后原因");
        return false;
      }
      let form = Object.assign({}, this.refundForm);
      form.orderSn = this.info.orderId;
      form.refundType = this.totalNumber == this.refundTotalNum ? 1 : 2;
      // refundTrend 0不退款 1 仅退款 2退货退款3仅退货不退款4仅退款不退货
      form.refundTrend = this.type;
      form.refundImage = this.imageUrl.join(",");
      form.productData = this.refundProductList
        .filter((item) => {
          return item.num != 0 || !item.num;
        })
        .map((item) => {
          return item.unique + "|" + (item.num || 0);
        })
        .join(",");
      form.applyType = this.type;
      console.log(form);

      // form.refundType = 1   //	1 整单退 2单品退
      // if (this.info.status == 6) {  //待发货 仅退款
      //     form.applyType = 1
      //     form.refundTrend = 1
      // } else if (this.info.status != 6) {
      //     form.refundImage = this.imageUrl.join(',')
      //     form.applyType = 2
      // }
      // let list = []
      // this.refundProductList.map(item => {
      //     if (item.isAfter == 0 && item.cartNum) {
      //         console.log(item);
      //         list.push(item.unique + '|' + item.cartNum)
      //     }
      // })
      // form.productData = list.join(',')
      // if (!form.productData) {
      //     this.$message.error(this.refundProductList.length > 0 ? '无可退商品' : "请选择退款商品")
      //     return false
      // }
      // console.log(form);
      // form.refundPrice = this.refundTotalPrice

      // this.shloading = true
      applyRefund(form)
        .then((res) => {
          this.shloading = false;
          if (res.data.status == 200) {
            this.$message.success(res.data.msg);
            this.refundItem = false;
            this.refundForm = {};
            this.refundProductList = [];
            this.selectedRowKeys = [];
            this.selectedRows = [];
            this.type = 0;
            // this.getData()
            this.$emit("subSuccess");
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.shloading = false;
        });
    },
  },
};
const columns = [
  {
    title: "序号",
    dataIndex: "index",
    key: "index",
    width: 80,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: "商品/套餐名称",
    dataIndex: "goods",
    key: "goods",
    width: 340,
    scopedSlots: { customRender: "goods" },
    align: "center",
  },
  {
    title: "ISBN",
    dataIndex: "isbn",
    key: "isbn",
    customRender: (text) => {
      return text || "/";
    },
    align: "center",
    width: 140,
  },
  {
    title: "商品编码",
    dataIndex: "barCode",
    key: "barCode",
    align: "center",
    width: 140,
  },
  {
    title: "数量",
    dataIndex: "cartNum",
    key: "cartNum",
    align: "center",
    width: 100,
  },
  {
    title: "单位",
    dataIndex: "unit",
    key: "unit",
    align: "center",
    width: 100,
  },
  {
    title: "定制内容",
    dataIndex: "isCust",
    key: "isCust",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "cust" },
  },
  {
    title: "市场价(元)",
    dataIndex: "price",
    key: "price",
    customRender: (text) => {
      return typeof text == "number" ? text.toFixed(2) : text;
    },
    align: "center",
    width: 120,
  },
  {
    title: "订货价(元)",
    dataIndex: "deductionUnitPrice",
    align: "center",
    width: 120,
    customRender: (text) => {
      return typeof text == "number" ? text.toFixed(2) : text;
    },
  },
  {
    title: "金额(元)",
    dataIndex: "totalPrice",
    align: "center",
    customRender: (text) => {
      return typeof text == "number" ? text.toFixed(2) : text;
    },
    width: 120,
  },
  {
    title: "售后操作",
    dataIndex: "active",
    align: "center",
    width: 170,
    scopedSlots: { customRender: "active" },
    fixed: "right",
  },
];
</script>

<style lang="less" scoped>
@import "../orderDetail";
.color_E04B4B {
  color: #e04b4b;
}
.more_product {
  margin-top: 16px;
  text-align: left;
  color: #3ab887;
  font-size: 12px;
  cursor: pointer;
}
.goods_g p {
  color: red;
  margin: 2px 0px;
}
</style>
